import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CellAlertSeverity } from '@dpdhl-iot/predictive-maintenance';
import {
  DateRangePickerComponent,
  SkeletonCardComponent,
  SliderComponent,
  TabsModule,
} from '@dpdhl/angular-shared-ui';
import { TranslateModule } from '@ngx-translate/core';
import { CellAnalysisDiagramModel } from './cell-analysis-diagram-model';
import { CellAnalysisComponent } from './cell-analysis/cell-analysis.component';
import { CombinedCellAnalysisComponent } from './combined-cell-analysis/combined-cell-analysis.component';
import { FrequencyAnalysisCellComponentDataService } from './frequency-analysis-cell.component-data.service';

@Component({
  standalone: true,
  selector: 'app-frequency-analysis-cell',
  templateUrl: './frequency-analysis-cell.component.html',
  styleUrls: ['./frequency-analysis-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  providers: [FrequencyAnalysisCellComponentDataService],
  imports: [
    CommonModule,
    DateRangePickerComponent,
    SliderComponent,
    TabsModule,
    SkeletonCardComponent,
    TranslateModule,
    CellAnalysisComponent,
    CombinedCellAnalysisComponent,
  ],
})
export class FrequencyAnalysisCellComponent implements OnChanges {
  @Input() selectedCell = -1;
  @ViewChild('frequencyImage', { read: ElementRef }) frequencyImageElem: ElementRef;

  selectedTab = 0;
  mouseOverText = '';
  diagramModels: CellAnalysisDiagramModel[] = [];
  noiseDiagramModels: CellAnalysisDiagramModel[] = [];
  readonly alertDataIndex = 0;
  readonly warningDataIndex = 1;

  constructor(protected readonly dataService: FrequencyAnalysisCellComponentDataService) {
    this.dataService.cellFrequencyAnalysis$.pipe(takeUntilDestroyed()).subscribe((results) => {
      results?.forEach((result) => {
        const deviceId = result.device.id;
        const deviceName = result.device.name ?? result.device.id;
        const diagramModel = this.getDiagramModel(this.diagramModels, deviceId, deviceName);
        const noiseDiagramModel = this.getDiagramModel(
          this.noiseDiagramModels,
          deviceId,
          deviceName,
        );
        if (!result) {
          return;
        }
        if (
          result.cellFrequencyData.length >= 2 &&
          result.cellFrequencyData[0].byteLength &&
          result.cellFrequencyData[1]
        ) {
          diagramModel.frequencyDataCellTimes = result.cellFrequencyData[1];
          diagramModel.cellFrequencyData.push(result.cellFrequencyData[0]);
        }

        if (
          result.triggeringFactors.length >= 3 &&
          result.triggeringFactors[0].byteLength &&
          result.triggeringFactors[1].byteLength &&
          result.triggeringFactors[2].timestamps &&
          result.triggeringFactors[2].frequencies
        ) {
          noiseDiagramModel.frequencyDataCellTimes = result.triggeringFactors[2].timestamps;
          const noiseFrequencyRangeString = result.triggeringFactors[2].frequencies;
          noiseDiagramModel.frequencyRangeString = noiseFrequencyRangeString.filter(
            (_, index) => (index + 1) % 10 === 0,
          );
          noiseDiagramModel.frequencyRangeString.unshift('0');
          noiseDiagramModel.frequencyRange = noiseFrequencyRangeString.map((n) => +n);
          noiseDiagramModel.cellFrequencyData.push(result.triggeringFactors[this.alertDataIndex]);
          noiseDiagramModel.cellFrequencyData.push(result.triggeringFactors[this.warningDataIndex]);
        }

        if (result.frequencyScale) {
          diagramModel.frequencyRangeString = result.frequencyScale.map((n) => n.toString());
          diagramModel.frequencyRange = result.frequencyScale;
        }

        if (result.alertHistory) {
          diagramModel.alertMarkGroups = result.alertHistory;
        }
      });
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedCell?.currentValue !== undefined && !changes.selectedCell.isFirstChange()) {
      this.dataService.cell = changes.selectedCell.currentValue;
    }
  }

  tabSelected(tabIndex: number) {
    if (this.selectedTab === tabIndex) {
      return;
    }
    this.selectedTab = tabIndex;
  }

  private clearFrequencyImage(diagramModel: CellAnalysisDiagramModel) {
    diagramModel.frequencyDataCellTimes = [];
    diagramModel.frequencyRange = [];
    diagramModel.frequencyRangeString = [];
    diagramModel.cellFrequencyData = [];
    diagramModel.alertMarkGroups = {};
  }

  private getDiagramModel(
    source: CellAnalysisDiagramModel[],
    deviceId: string,
    deviceName: string,
  ): CellAnalysisDiagramModel {
    let diagramModel = source.find((m) => m.deviceId === deviceId)!;
    if (diagramModel) {
      this.clearFrequencyImage(diagramModel);
    } else {
      diagramModel = {
        deviceId,
        deviceName,
        frequencyDataCellTimes: [],
        frequencyRange: [],
        frequencyRangeString: [],
        cellFrequencyData: [],
        alertMarkGroups: {},
      };
      source.push(diagramModel);
    }
    return diagramModel;
  }

  protected readonly Date = Date;
  protected readonly CellAlertSeverity = CellAlertSeverity;
}
