import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CellAnalysisDiagramModel } from '../cell-analysis-diagram-model';
import { CellAnalysisComponent } from '../cell-analysis/cell-analysis.component';

@Component({
  standalone: true,
  selector: 'app-combined-cell-analysis',
  templateUrl: './combined-cell-analysis.component.html',
  styleUrls: ['./combined-cell-analysis.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  imports: [CommonModule, TranslateModule, CellAnalysisComponent],
})
export class CombinedCellAnalysisComponent {
  @Input() diagramModels: CellAnalysisDiagramModel[] = [];
  @Input() dataIndex = 0;
}
