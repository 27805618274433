<div class="px-24 space-y-16">
  <div class="flex items-center justify-between space-x-24 w-3/4">
    <div class="w-4/6">
      <dpdhl-date-range-picker
        [_formGroup]="dataService.formGroup"
        label="{{ 'predictiveMaintenance.history.dateRange' | translate }}"
        _formControlName="dateRange"
        class="w-1/3 mr-32"
      ></dpdhl-date-range-picker>
    </div>

    <div class="w-2/6">
      <div class="text-footnote text-secondary">
        {{ 'predictiveMaintenance.frequencyAnalysis.colorContrast' | translate }}
      </div>
      <dpdhl-slider
        [_formGroup]="dataService.formGroup"
        _formControlName="colorContrast"
        [showThumbLabel]="true"
        [min]="2"
        [max]="20"
      ></dpdhl-slider>
    </div>
  </div>
</div>
<div class="grid grid-cols-4 space-12">
  <div class="col-span-4 h-full px-24">
    <dpdhl-tabs [active]="selectedTab" (select)="tabSelected($event)" data-test-id="tabs-element">
      <dpdhl-tab-element *dpdhlTabElement>{{
        'predictiveMaintenance.frequencyAnalysis.frequencyTriggeringFactors' | translate
      }}</dpdhl-tab-element>
      <dpdhl-tab-element *dpdhlTabElement>{{
        'predictiveMaintenance.frequencyAnalysis.frequencyCellAnalysis' | translate
      }}</dpdhl-tab-element>
    </dpdhl-tabs>
  </div>

  @if (diagramModels.length > 0) {
    <div class="col-span-4 h-full px-24" [hidden]="selectedTab !== 1 || dataService.loading">
      <app-combined-cell-analysis
        [diagramModels]="diagramModels"
        data-test-id="cell-analysis"
      ></app-combined-cell-analysis>
    </div>
  }

  @if (noiseDiagramModels.length > 0) {
    <div class="col-span-4 h-full px-24" [hidden]="selectedTab !== 0 || dataService.loading">
      <div class="text-headline1 mt-16 h-32">
        {{ 'predictiveMaintenance.frequencyAnalysis.alertFrequency' | translate }}
      </div>
      <app-combined-cell-analysis
        [diagramModels]="noiseDiagramModels"
        [dataIndex]="alertDataIndex"
        data-test-id="triggering-factors-alert"
      ></app-combined-cell-analysis>
      <div class="text-headline1 mt-16 h-32">
        {{ 'predictiveMaintenance.frequencyAnalysis.warningFrequency' | translate }}
      </div>
      <app-combined-cell-analysis
        [diagramModels]="noiseDiagramModels"
        [dataIndex]="warningDataIndex"
        data-test-id="triggering-factors-warning"
      ></app-combined-cell-analysis>
    </div>
  }
</div>
@if (dataService.loading) {
  <div class="w-full h-full px-24">
    <dpdhl-skeleton-card [classes]="['frequency-loading']"
      ><p class="p-8 font-bold-italic text-secondary text-body1">
        {{ 'predictiveMaintenance.frequencyAnalysis.loading' | translate }}
      </p></dpdhl-skeleton-card
    >
  </div>
}
