import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
  DeviceDetailsPredictiveMaintenance,
  PredictiveMaintenanceAlertDataService,
  PredictiveMaintenanceService,
} from '@dpdhl-iot/predictive-maintenance';
import { NotificationDataService, NotificationType } from '@dpdhl/iot-shared-ui';
import { DateTime } from 'luxon';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  of,
  switchMap,
  tap,
  throwError,
  zip,
} from 'rxjs';

@Injectable()
export class FrequencyAnalysisCellComponentDataService {
  public formGroup = new FormGroup({
    dateRange: new FormControl<Date[]>([
      DateTime.now().minus({ month: 1.25 }).startOf('day').toJSDate(),
      DateTime.now().endOf('day').toJSDate(),
    ]),
    cell: new FormControl<number>(-1),
    colorContrast: new FormControl<number>(5),
  });

  public loading = true;

  constructor(
    private readonly service: PredictiveMaintenanceService,
    private readonly alertService: PredictiveMaintenanceAlertDataService,
    private readonly notificationService: NotificationDataService,
  ) {}

  set cell(value: number) {
    this.formGroup.controls.cell.setValue(value);
  }

  get cellFrequencyAnalysis$() {
    return this.service.filteredPredictiveMaintenanceDevices.pipe(
      distinctUntilChanged(
        (prev, curr) => prev.map((x) => x.deviceId).join() === curr.map((x) => x.deviceId).join(),
      ),
      filter((devices) => devices.length > 0),
      switchMap((devices) =>
        this.formGroup.valueChanges.pipe(
          debounceTime(500),
          filter(() => this.formGroup.valid),
          tap(() => {
            this.loading = true;
          }),
          mergeMap(() => zip(devices.map((x) => this.mergeCellFrequencyAnalysisData(x)))),
        ),
      ),
      tap(() => {
        this.loading = false;
      }),
      catchError(() => {
        this.notificationService.addNotification({
          type: NotificationType.ERROR,
          text: 'Failed to fetch frequency image',
        });
        return of(null);
      }),
    );
  }

  get devices$() {
    return this.service.filteredPredictiveMaintenanceDevices;
  }

  private mergeCellFrequencyAnalysisData(device: DeviceDetailsPredictiveMaintenance) {
    const selectedCell = this.formGroup.value.cell ?? 0;
    const deviceId = device.deviceId;
    const contrast = this.formGroup.value.colorContrast;
    const zeroCell = device.sensor.beltZeroCell ?? 0;

    if (
      deviceId &&
      selectedCell >= zeroCell &&
      contrast &&
      this.formGroup.value.dateRange?.length
    ) {
      const from = DateTime.fromJSDate(this.formGroup.value.dateRange?.[0])
        .startOf('day')
        .toJSDate();
      const to = DateTime.fromJSDate(this.formGroup.value.dateRange?.[1]).endOf('day').toJSDate();
      const colorContrast = this.formGroup.value.colorContrast ?? 5;

      const cellFrequencyData$ = this.service.getCellFrequencyData(
        deviceId,
        selectedCell,
        from,
        to,
        colorContrast,
      );
      const triggeringFactors$ = this.service.getAlertTriggeringFactors(
        deviceId,
        selectedCell,
        from,
        to,
      );
      const alertHistory$ = this.alertService.loadAlertHistory(
        selectedCell,
        deviceId,
        from.getTime(),
        to.getTime(),
      );

      const frequencyScale$ = this.service.getScale(deviceId);

      return zip([cellFrequencyData$, triggeringFactors$, frequencyScale$, alertHistory$]).pipe(
        map((results) => ({
          cellFrequencyData: results[0],
          triggeringFactors: results[1],
          frequencyScale: results[2],
          alertHistory: results[3],
          device: { id: deviceId, name: device.deviceName },
        })),
      );
    } else {
      return throwError(() => 'VALIDATION_ERROR');
    }
  }
}
