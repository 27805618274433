<div class="relative diagram">
  @if (diagramModels.length > 0) {
    <div class="grid grid-cols-2 space-12">
      <app-cell-analysis
        class="pt-8"
        [diagramModel]="diagramModels[0]"
        [cellFrequencyData]="diagramModels[0].cellFrequencyData[0]"
        [showFrequencyAxis]="false"
        data-test-id="cell-analysis-left"
      ></app-cell-analysis>
      @if (diagramModels.length > 1) {
        <app-cell-analysis
          class="pt-8"
          [diagramModel]="diagramModels[1]"
          [cellFrequencyData]="diagramModels[1].cellFrequencyData[0]"
          [showFrequencyAxis]="false"
          data-test-id="cell-analysis-right"
        ></app-cell-analysis>
      }
    </div>
    <div
      class="frequency-range flex flex-col-reverse justify-between items-end absolute top-48 bottom-16 pb-16 text-body2"
      data-test-id="frequencyRangeContainer"
    >
      <div
        *ngFor="let frequency of diagramModels[0].frequencyRangeString"
        class="relative leading-4"
      >
        <span>{{ frequency }} Hz</span>
        <span class="line w-12 absolute top-1/2 -right-16 bg-gray-200"></span>
      </div>
    </div>
  }
</div>
